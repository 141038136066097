<template>
  <div>
    <div class="mb-2 lg:mb-4">
      <span class="text-dark-grey text-xs font-bold lg:text-sm">{{
        t("trending")
      }}</span>
    </div>
    <div>
      <div class="block">
        <a :href="links.rooftop" class="recommended-pills">{{
          t("rooftop")
        }}</a>
        <a :href="links.omakase" class="recommended-pills">{{
          t("omakase")
        }}</a>
        <a :href="links.waterfront" class="recommended-pills">{{
          t("waterfront")
        }}</a>
        <a :href="links.ayce" class="recommended-pills">{{ t("ayce") }}</a>
        <a :href="links.pp" class="recommended-pills">{{ t("pp") }}</a>
        <a :href="links.xp" class="recommended-pills">{{ t("xp") }}</a>
        <a :href="links.hah" class="recommended-pills">{{ t("hah") }}</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const links = computed(() => {
  const rooftopId = import.meta.env.NUXT_MODE === "development" ? 18 : 1650;
  const omakaseId = import.meta.env.NUXT_MODE === "development" ? 1387 : 1659;
  const waterfrontId =
    import.meta.env.NUXT_MODE === "development" ? 1415 : 1655;

  return {
    rooftop: `/restaurants/search?dining_style_id[]=${rooftopId}&dining_style_name[]=${t(
      "rooftop"
    )}`,
    omakase: `/restaurants/search?dining_style_id[]=${omakaseId}&dining_style_name[]=${t(
      "omakase"
    )}`,
    waterfront: `/restaurants/search?dining_style_id[]=${waterfrontId}&dining_style_name[]=${t(
      "waterfront"
    )}`,
    ayce: "/restaurants/search?package_type[]=ayce&dine_in=true",
    pp: "/restaurants/search?package_type[]=pp&dine_in=true",
    xp: "/restaurants/search?package_type[]=xp",
    hah: "/restaurants/search?package_type[]=hah&delivery=true",
  };
});
</script>

<i18n lang="json">
{
  "en": {
    "rooftop": "Rooftop",
    "omakase": "Omakase",
    "waterfront": "Waterfront",
    "ayce": "All You Can Eat",
    "pp": "Party Pack",
    "xp": "Experience",
    "hah": "Delivery",
    "trending": "Trending"
  },
  "th": {
    "rooftop": "รูฟท็อป",
    "omakase": "โอมากาเสะ",
    "waterfront": "ริมน้ำ",
    "ayce": "บุฟเฟต์",
    "pp": "ปาร์ตี้แพ็ก",
    "xp": "เอ็กซ์พีเรียนซ์",
    "hah": "เดลิเวอรี่",
    "trending": "แนวโน้ม"
  }
}
</i18n>

<style lang="scss" scoped>
.recommended-pills {
  @apply mb-2 mr-2 inline-block rounded-3xl border border-[#DEE2E4] bg-white px-2 py-2 text-xs font-normal text-[#2A2B2A] lg:mb-4 lg:mr-4 lg:px-4 lg:py-3 lg:text-sm;
}
</style>
